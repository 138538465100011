import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide47/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide47/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide47/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide47/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide47/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo calcular el costo por lead en telemarketing
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      El Costo por Lead es una herramienta esencial para optimizar nuestras
      campañas de marketing, ya que nos permite medir la eficiencia de nuestros
      esfuerzos. Básicamente, el nos dice cuánto dinero estamos gastando para
      obtener un nuevo cliente potencial.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Un lead, en este contexto, es alguien que muestra interés en nuestros
    productos o servicios al realizar una acción específica, como proporcionar
    su información de contacto. Es importante entender que cada lead representa
    una oportunidad de negocio futuro.
    <br />
    <br />
    Al calcular el CPL, podemos establecer presupuestos publicitarios adecuados
    y evaluar el retorno de inversión de nuestras estrategias de marketing.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Cuando se utiliza el costo por lead.",
      },
      {
        key: 2,
        text: "Calcula el costo por lead en 3 pasos.",
      },
      {
        key: 3,
        text: "Fórmula para calcular el costo por lead.",
      },
      {
        key: 4,
        text: "Reducir el costo por lead.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
